// extracted by mini-css-extract-plugin
export var socialBlock = "c1_w1";
export var socialBlock__icon = "c1_w6";
export var socialBlock__iconWrapper = "c1_w5";
export var socialBlock__icon_facebook = "c1_w7";
export var socialBlock__icon_instagram = "c1_xb";
export var socialBlock__icon_linkedin = "c1_w8";
export var socialBlock__icon_telegram = "c1_xc";
export var socialBlock__icon_tiktok = "c1_xd";
export var socialBlock__icon_twitter = "c1_w9";
export var socialBlock__iconsWrapper = "c1_w4";
export var socialBlock__sprite = "c1_w3";
export var socialBlock__title = "c1_w2";