// extracted by mini-css-extract-plugin
export var recentPost = "bH_mC";
export var recentPost__button = "bH_mT";
export var recentPost__postAuthor = "bH_mN";
export var recentPost__postAuthorImage = "bH_mM";
export var recentPost__postAuthorName = "bH_mP";
export var recentPost__postCard = "bH_mD";
export var recentPost__postCategory = "bH_mH";
export var recentPost__postDate = "bH_mS";
export var recentPost__postDescriprion = "bH_mK";
export var recentPost__postImage = "bH_mG";
export var recentPost__postInfo = "bH_mQ";
export var recentPost__postInfoWrapper = "bH_mL";
export var recentPost__postLink = "bH_mF";
export var recentPost__postStats = "bH_mR";
export var recentPost__postTitle = "bH_mJ";
export var recentPost__title = "bH_mV";